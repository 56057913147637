import logo from "../../assets/img/logo.svg";

export const ScreenLoader = () => {
  return (
    <section className="flex h-screen items-center justify-center p-4">
      <section className="mx-auto flex w-full max-w-md flex-col rounded bg-white p-4 shadow-lg">
        <div className="mx-auto flex flex-col gap-2 md:flex-row">
          <img
            src={logo}
            alt=""
            className="mx-auto h-32 w-32 animate-pulse md:mx-0"
          />
          <section className="h-32 flex-col items-start justify-between py-2 text-3xl uppercase">
            <span className="block whitespace-nowrap md:inline-block">
              Red Cántabra
            </span>
            <span className="block whitespace-nowrap font-light md:inline-block">
              de desarrollo
            </span>
            <span className="block whitespace-nowrap font-light md:inline-block">
              rural
            </span>
          </section>
        </div>
      </section>
    </section>
  );
};
