import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

const COLOR_SCHEME = {
  slate: {
    icon: {
      wrapper: "bg-slate-100",
      accent: "text-slate-800",
    },
    button: "bg-slate-700 hover:bg-slate-800 focus:ring-slate-500",
  },
  gray: {
    icon: {
      wrapper: "bg-gray-100",
      accent: "text-gray-800",
    },
    button: "bg-gray-700 hover:bg-gray-800 focus:ring-gray-500",
  },
  zinc: {
    icon: {
      wrapper: "bg-zinc-100",
      accent: "text-zinc-800",
    },
    button: "bg-zinc-700 hover:bg-zinc-800 focus:ring-zinc-500",
  },
  neutral: {
    icon: {
      wrapper: "bg-neutral-100",
      accent: "text-neutral-800",
    },
    button: "bg-neutral-700 hover:bg-neutral-800 focus:ring-neutral-500",
  },
  stone: {
    icon: {
      wrapper: "bg-stone-100",
      accent: "text-stone-800",
    },
    button: "bg-stone-700 hover:bg-stone-800 focus:ring-stone-500",
  },
  red: {
    icon: {
      wrapper: "bg-red-100",
      accent: "text-red-800",
    },
    button: "bg-red-700 hover:bg-red-800 focus:ring-red-500",
  },
  orange: {
    icon: {
      wrapper: "bg-orange-100",
      accent: "text-orange-800",
    },
    button: "bg-orange-700 hover:bg-orange-800 focus:ring-orange-500",
  },
  amber: {
    icon: {
      wrapper: "bg-amber-100",
      accent: "text-amber-800",
    },
    button: "bg-amber-700 hover:bg-amber-800 focus:ring-amber-500",
  },
  yellow: {
    icon: {
      wrapper: "bg-yellow-100",
      accent: "text-yellow-800",
    },
    button: "bg-yellow-700 hover:bg-yellow-800 focus:ring-yellow-500",
  },
  lime: {
    icon: {
      wrapper: "bg-lime-100",
      accent: "text-lime-800",
    },
    button: "bg-lime-700 hover:bg-lime-800 focus:ring-lime-500",
  },
  green: {
    icon: {
      wrapper: "bg-green-100",
      accent: "text-green-800",
    },
    button: "bg-green-700 hover:bg-green-800 focus:ring-green-500",
  },
  emerald: {
    icon: {
      wrapper: "bg-emerald-100",
      accent: "text-emerald-800",
    },
    button: "bg-emerald-700 hover:bg-emerald-800 focus:ring-emerald-500",
  },
  teal: {
    icon: {
      wrapper: "bg-teal-100",
      accent: "text-teal-800",
    },
    button: "bg-teal-700 hover:bg-teal-800 focus:ring-teal-500",
  },
  cyan: {
    icon: {
      wrapper: "bg-cyan-100",
      accent: "text-cyan-800",
    },
    button: "bg-cyan-700 hover:bg-cyan-800 focus:ring-cyan-500",
  },
  sky: {
    icon: {
      wrapper: "bg-sky-100",
      accent: "text-sky-800",
    },
    button: "bg-sky-700 hover:bg-sky-800 focus:ring-sky-500",
  },
  blue: {
    icon: {
      wrapper: "bg-blue-100",
      accent: "text-blue-800",
    },
    button: "bg-blue-700 hover:bg-blue-800 focus:ring-blue-500",
  },
  indigo: {
    icon: {
      wrapper: "bg-indigo-100",
      accent: "text-indigo-800",
    },
    button: "bg-indigo-700 hover:bg-indigo-800 focus:ring-indigo-500",
  },
  violet: {
    icon: {
      wrapper: "bg-violet-100",
      accent: "text-violet-800",
    },
    button: "bg-violet-700 hover:bg-violet-800 focus:ring-violet-500",
  },
  purple: {
    icon: {
      wrapper: "bg-purple-100",
      accent: "text-purple-800",
    },
    button: "bg-purple-700 hover:bg-purple-800 focus:ring-purple-500",
  },
  fuchsia: {
    icon: {
      wrapper: "bg-fuchsia-100",
      accent: "text-fuchsia-800",
    },
    button: "bg-fuchsia-700 hover:bg-fuchsia-800 focus:ring-fuchsia-500",
  },
  pink: {
    icon: {
      wrapper: "bg-pink-100",
      accent: "text-pink-800",
    },
    button: "bg-pink-700 hover:bg-pink-800 focus:ring-pink-500",
  },
  rose: {
    icon: {
      wrapper: "bg-rose-100",
      accent: "text-rose-800",
    },
    button: "bg-rose-700 hover:bg-rose-800 focus:ring-rose-500",
  },
  default: {
    icon: {
      wrapper: "bg-neutral-100",
      accent: "text-neutral-800",
    },
    button: "bg-neutral-700 hover:bg-neutral-800 focus:ring-neutral-500",
  },
};

export const Confirm = ({
  title,
  text,
  confirmText = "¡Adelante!",
  cancelText = "¡Espera!",
  color = "rose",
  confirmAction = () => {},
  cancelAction = () => {},
  Icon = ExclamationIcon,
}) => {
  const scheme = COLOR_SCHEME[color] || COLOR_SCHEME["default"];
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={cancelAction}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 ${scheme.icon.wrapper}`}
                  >
                    <Icon
                      className={`h-6 w-6 ${scheme.icon.accent}`}
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="mb-4 text-xl leading-7 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2 text-gray-800">{text}</div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${scheme.button}`}
                  onClick={confirmAction}
                >
                  {confirmText}
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rcdr-green focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={cancelAction}
                  ref={cancelButtonRef}
                >
                  {cancelText}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
