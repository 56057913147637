import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Button, SelectControl } from ".";

export const Pagination = ({
  page = 1,
  setPage = () => {},
  limit = 50,
  setLimit = () => {},
  hasNext = false,
  isLoading,
}) => {
  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white py-3">
      <div className="flex justify-between">
        <p className="text-sm text-gray-700">Página {page}</p>
      </div>
      <div className="flex justify-between gap-4">
        <Button
          color="zinc"
          onClick={() => setPage(page - 1)}
          disabled={page === 1 || isLoading}
        >
          <ChevronLeftIcon className="inline h-5 w-5" aria-hidden="true" />{" "}
          Anterior
        </Button>
        <Button
          color="zinc"
          onClick={() => setPage(page + 1)}
          disabled={isLoading || !hasNext}
        >
          Siguiente{" "}
          <ChevronRightIcon className="inline h-5 w-5" aria-hidden="true" />
        </Button>
      </div>
      <div>
        <SelectControl
          id={`pagination-limit-${Math.random().toString(36).substring(2, 15)}`}
          label={false}
          placeholder={false}
          options={[25, 50, 100].map((option) => ({
            label: option,
            value: option,
          }))}
          errors={{}}
          selected={limit}
          inputProps={{
            defaultValue: limit,
            disabled: isLoading,
            onChange: (e) => setLimit(parseInt(e.target.value)),
          }}
        />
      </div>
    </div>
  );
};
