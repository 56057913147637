import { ChevronRightIcon, HomeIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export const Breadcrumbs = ({ parents = [], current = "" }) => {
  return (
    <nav className="mt-2 mb-4 hidden sm:flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        <li className="inline-flex items-center">
          <Link
            to="/"
            className="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
          >
            <HomeIcon className="mr-2 h-4 w-4" />
            Inicio
          </Link>
        </li>
        {parents.map((item, idx) => {
          return (
            <li key={idx}>
              <div className="flex items-center">
                <ChevronRightIcon className="h-6 w-6 text-gray-400" />
                <Link
                  to={item.path}
                  className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white md:ml-2"
                >
                  {item.text}
                </Link>
              </div>
            </li>
          );
        })}
        <li aria-current="page">
          <div className="flex items-center">
            <ChevronRightIcon className="h-6 w-6 text-gray-400" />
            <span className="ml-1 text-sm font-medium text-gray-400 dark:text-gray-500 md:ml-2">
              {current}
            </span>
          </div>
        </li>
      </ol>
    </nav>
  );
};
