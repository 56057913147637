import { format } from "date-fns";
import { es } from "date-fns/locale";
import toast from "react-hot-toast";
import { Toast } from "./components/ui";
import { EVENTS_URL_FILTERS } from "./global";

/**
 * @description Fetch por defecto.
 * @param {string} resource Endpoint al que hay que llamar.
 * @param {Object} init Configuración para el fetch.
 * @returns {Promise}
 */
export const defaultFetcher = async (resource, init = {}) => {
  const response = await fetch(resource, init);

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the data.");

    error.info = await response.json();
    error.status = response.status;
    throw error;
  }

  return response.json();
};

/**
 * @description Fetch múltiple por defecto.
 * @param {} fetchs Collección de llamadas con el formato de `defaultFetcher`.
 * @returns {Promise}
 */
export const defaultMultiFetcher = async (...fetchs) =>
  Promise.all(
    fetchs.map(([resource, init = {}]) => defaultFetcher(resource, init))
  );

/**
 * @description Elimina los espacios en blanco de un teléfono.
 * @param {string} phone Número de teléfono.
 * @returns {string} Con el número de teléfono.
 * @example "942 943 483" -> "942943483"
 */
export const cleanPhone = (phone) => phone.replace(/\s/g, "");

/**
 * @description Determina si el número de teléfono es válido o no.
 * @param {string} phone Número de teléfono.
 * @returns {bool} Si es un número de teléfono válido.
 * @example "942943483" -> true
 * @example "+34942943483" -> true
 * @example "abc942943483" -> false
 * @example "942 943 483" -> false
 */
export const checkPhone = (phone) => phone.match(/^\+?\d+$/gm);

/**
 * @description Muestra un toast personalizado.
 * @param {string} color Color del toast.
 * @param {JSX.Element} icon Icono del toast.
 * @param {string} title Título del toast.
 * @param {string|JSX.Element} text Texto a mostrar.
 */
export const notify = (color, icon, title, text) => {
  toast.custom((t) => (
    <Toast
      color={color}
      icon={icon}
      title={title}
      text={text}
      close={() => toast.remove(t.id)}
    />
  ));
};

/**
 * @description Devuelve las iniciales de un nombre completo.
 * @param {string} name Nombre del que sacar las iniciales.
 * @returns {string}
 * @example "Ángel" => "ÁN"
 * @example "Ángel Guerra" => "ÁG"
 * @example "Ángel Luis Guerra" => "ÁG"
 */
export const getInitials = (name) => {
  return name
    .match(/(^\S\S?|\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();
};

/**
 * @description Construye la URL para pedir el listado de eventos, teniendo en cuenta que estos pueden ser "normales" o "grupales".
 * @param {string} base URL base.
 * @param {bool} calendar Determina si se está llamando desde el calendario.
 * @param {number|Date|null} startDate Fecha de inicio.
 * @param {number|Date|null} endDate Fecha de fin.
 * @param {string[]} centers Centros interpretativos (usuarios).
 * @param {string|null} canceled Determina si se quieren los eventos cancelados, no cancelados o todos.
 * @param {number} page Página solicitada.
 * @param {number} limit Límite de registros.
 * @returns {string}
 */
export const constructEventsEndpoint = (
  base,
  calendar = false,
  startDate = null,
  endDate = null,
  centers = [],
  canceled = null,
  page = 1,
  limit = 50
) => {
  const url = new URL(base);
  const params = [
    calendar ? [EVENTS_URL_FILTERS.calendar, 1] : [],
    startDate
      ? [EVENTS_URL_FILTERS.start_date, format(startDate, "yyyy-MM-dd")]
      : [],
    endDate ? [EVENTS_URL_FILTERS.end_date, format(endDate, "yyyy-MM-dd")] : [],
    ...(centers.length
      ? centers.map((center) => [`${EVENTS_URL_FILTERS.centers}[]`, center])
      : []),
    canceled ? [EVENTS_URL_FILTERS.canceled, canceled] : [],
    [EVENTS_URL_FILTERS.page, page],
    [EVENTS_URL_FILTERS.limit, limit],
  ].filter((param) => param.length);

  url.search = new URLSearchParams(params).toString();

  return url;
};

/**
 * @description Funcionamiento por defecto de los fetchs.
 * @param {string} endpoint Endpoint de la API.
 * @param {object} request Configuración de la petición.
 * @param {CallableFunction} successCallback Función a ejecutar si todo va bien.
 * @param {CallableFunction} failCallback Función si no retorna un success => true.
 * @param {CallableFunction} catchCallback Función si ocurre un error y entra por el catch.
 * @returns {Promise}
 */
export const defaultFetchBehaviour = async (
  endpoint,
  request,
  successCallback,
  failCallback,
  catchCallback
) => {
  return defaultFetcher(endpoint, request)
    .then((response) => {
      if (response.data?.success) {
        successCallback(response);

        return;
      }

      failCallback(response);
    })
    .catch((error) => {
      catchCallback(error);
    });
};

/**
 * @description Ejecuta una función con un retardo dado.
 * @param {CallableFunction} func Función a ejecutar.
 * @param {int} delay Retardo de la función.
 * @returns {CallableFunction}
 */
export const debounce = (func, delay) => {
  let timer;
  return function () {
    let [self, args] = [this, arguments];
    clearTimeout(timer);

    timer = setTimeout(() => {
      func.apply(self, args);
    }, delay);
  };
};

/**
 * @description Formatea la fecha con date-fns, hay que tener en cuenta que
 * `new Date('2022-09-01 17:00:00')` no funciona en Safari, aunque en el resto
 * sí, por lo que hay que corregir el formato a `new Date('2022-09-01T17:00:00')`.
 * @param {string|Date} date Fecha a formatear.
 * @param {string} f Formato a aplicar.
 * @returns
 */
export const formatDate = (date, f) => {
  if (date instanceof Date) {
    return format(date, f, { locale: es });
  }

  return format(new Date(date.split(" ").join("T")), f, { locale: es });
};
