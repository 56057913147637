import { ErrorMessage } from "@hookform/error-message";

export const CheckControl = ({
  id,
  label,
  errors,
  disabled = false,
  type = "checkbox",
  inputProps = {},
  labelProps = {},
}) => {
  const hasError = errors[id] ? true : false;

  return (
    <div className="mb-2">
      <label
        htmlFor={id}
        className={`flex cursor-pointer items-center ${
          disabled
            ? "cursor-not-allowed italic text-neutral-600"
            : hasError
            ? "text-rose-800"
            : "text-rcdr-green-dark"
        }`}
        {...labelProps}
      >
        <input
          id={id}
          type={type}
          className="border-2 border-gray-300 text-rcdr-green-dark focus:border-gray-300 focus:ring-rcdr-green-dark disabled:cursor-not-allowed disabled:text-neutral-600"
          aria-invalid={hasError ? "true" : "false"}
          aria-describedby={hasError ? `${id}-help` : ""}
          {...inputProps}
        />
        <span className="ml-2">{label}</span>
      </label>

      <ErrorMessage
        errors={errors}
        name={id}
        as={
          <span
            id={`${id}-help`}
            role="alert"
            className="text-xs text-rose-800"
          />
        }
      />
    </div>
  );
};
