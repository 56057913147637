export const TableCell = ({ title, children }) => {
  return (
    <td
      data-title={title}
      className="flex flex-col items-start gap-2 p-4 before:font-bold before:content-[attr(data-title)] sm:flex-row sm:before:flex-shrink-0 sm:before:flex-grow-0 sm:before:basis-28 xl:table-cell xl:w-auto xl:before:hidden"
    >
      {children}
    </td>
  );
};
