export const PageHeader = ({
  title,
  subtitle = null,
  links = null,
  children,
}) => (
  <header className="prose mx-auto mt-6 mb-12 flex max-w-2xl flex-col items-center justify-center">
    <h1 className="mb-5 text-center text-5xl font-bold">{title}</h1>
    {subtitle !== null && <p className="text-center">{subtitle}</p>}
    {children}
    {links !== null && (
      <div className="flex flex-wrap items-center justify-center gap-3">
        {links}
      </div>
    )}
  </header>
);
