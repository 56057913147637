import { ArrowDownIcon } from "@heroicons/react/outline";

export const Loader = ({ text = "Cargando datos..." }) => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 py-12">
      <ArrowDownIcon className="h-40 w-40 animate-bounce" />
      <span className="text-4xl font-bold">{text}</span>
    </div>
  );
};
