import { API_URL, DEFAULT_REQUEST_CONFIG } from "../global";
import { defaultFetcher } from "../helpers";

export const CALLS = {
  login: {
    request: { ...DEFAULT_REQUEST_CONFIG, method: "POST" },
    endpoint: `${API_URL}/users/login`,
  },
  logout: {
    request: DEFAULT_REQUEST_CONFIG,
    endpoint: `${API_URL}/users/logout`,
  },
  me: {
    request: DEFAULT_REQUEST_CONFIG,
    endpoint: `${API_URL}/manager/users/me`,
  },
  list: {
    request: DEFAULT_REQUEST_CONFIG,
    endpoint: `${API_URL}/manager/users/list`,
  },
};

/**
 * @description Autentica al usuario en la aplicación de Gestión.
 * @param {String} username Nombre de usuario.
 * @param {String} password Contraseña.
 * @returns {Object}
 */
export const login = async (username, password) => {
  const init = CALLS.login.request;
  init.body = JSON.stringify({ username: username, password: password });

  return defaultFetcher(CALLS.login.endpoint, init).then(
    (response) => response
  );
};

/**
 * @description Cierra la sesión del usuario autenticado.
 */
export const logout = async () =>
  defaultFetcher(CALLS.logout.endpoint, CALLS.logout.request);

/**
 * @description Devuelve la información del usuario autenticado.
 * @returns {Promise}
 */
export const getCurrentUser = async () =>
  defaultFetcher(CALLS.me.endpoint, CALLS.me.request);

/**
 * @description Retorna el listado de usuarios.
 * @returns {Promise}
 */
export const getUsersList = async () =>
  defaultFetcher(CALLS.list.endpoint, CALLS.list.request);
