import { InformationCircleIcon } from "@heroicons/react/outline";

const COLOR_SCHEME = {
  slate: {
    container: "border-slate-500 bg-slate-50",
    text: "text-slate-700",
  },
  gray: {
    container: "border-gray-500 bg-gray-50",
    text: "text-gray-700",
  },
  zinc: {
    container: "border-zinc-500 bg-zinc-50",
    text: "text-zinc-700",
  },
  neutral: {
    container: "border-neutral-500 bg-neutral-50",
    text: "text-neutral-700",
  },
  stone: {
    container: "border-stone-500 bg-stone-50",
    text: "text-stone-700",
  },
  red: {
    container: "border-red-500 bg-red-50",
    text: "text-red-700",
  },
  orange: {
    container: "border-orange-500 bg-orange-50",
    text: "text-orange-700",
  },
  amber: {
    container: "border-amber-500 bg-amber-50",
    text: "text-amber-700",
  },
  yellow: {
    container: "border-yellow-500 bg-yellow-50",
    text: "text-yellow-700",
  },
  lime: {
    container: "border-lime-500 bg-lime-50",
    text: "text-lime-700",
  },
  green: {
    container: "border-green-500 bg-green-50",
    text: "text-green-700",
  },
  emerald: {
    container: "border-amber-500 bg-amber-50",
    text: "text-emerald-700",
  },
  teal: {
    container: "border-teal-500 bg-teal-50",
    text: "text-teal-700",
  },
  cyan: {
    container: "border-cyan-500 bg-cyan-50",
    text: "text-cyan-700",
  },
  sky: {
    container: "border-sky-500 bg-sky-50",
    text: "text-sky-700",
  },
  blue: {
    container: "border-blue-500 bg-blue-50",
    text: "text-blue-700",
  },
  indigo: {
    container: "border-indigo-500 bg-indigo-50",
    text: "text-indigo-700",
  },
  violet: {
    container: "border-violet-500 bg-violet-50",
    text: "text-violet-700",
  },
  purple: {
    container: "border-purple-500 bg-purple-50",
    text: "text-purple-700",
  },
  fuchsia: {
    container: "border-violet-500 bg-violet-50",
    text: "text-fuchsia-700",
  },
  pink: {
    container: "border-pink-500 bg-pink-50",
    text: "text-pink-700",
  },
  rose: {
    container: "border-rose-500 bg-rose-50",
    text: "text-rose-700",
  },
  default: {
    container: "border-neutral-500 bg-neutral-50",
    text: "text-neutral-700",
  },
};

export const Alert = ({ color = "red", children }) => {
  const scheme = COLOR_SCHEME[color] || COLOR_SCHEME["default"];

  return (
    <div
      className={`mb-4 flex border-t-4 p-4 ${scheme.container}`}
      role="alert"
    >
      <InformationCircleIcon
        className={`h-5 w-5 flex-shrink-0 ${scheme.text}`}
      />
      <div className={`ml-3 text-sm ${scheme.text}`}>{children}</div>
    </div>
  );
};
