import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";

import { AuthProvider } from "../hooks/useAuth";
import Router from "./Router";

import { defaultFetcher } from "../helpers";

function App() {
  return (
    <BrowserRouter>
      <SWRConfig
        value={{
          // refreshInterval: 3000,
          fetcher: defaultFetcher,
        }}
      >
        <AuthProvider>
          <Router />
        </AuthProvider>
      </SWRConfig>
    </BrowserRouter>
  );
}

export default App;
