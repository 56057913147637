import { lazy, Suspense } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import { ScreenLoader } from "./ui";

const Layout = lazy(() => import("../components/Layout"));

/* Profile */
const ProfileIndex = lazy(() => import("../pages/Profile/index"));

/* Events */
const EventsIndex = lazy(() => import("../pages/Events/index"));
const EventsCalendar = lazy(() => import("../pages/Events/calendar"));
const EventsView = lazy(() => import("../pages/Events/view"));

/* Reservations */
const ReservationsNew = lazy(() => import("../pages/Reservations/new"));

/* Group Events */
const GroupEventsNew = lazy(() => import("../pages/GroupEvents/new"));
const GroupEventsEdit = lazy(() => import("../pages/GroupEvents/edit"));

/** Support Events */
const SupportEventsView = lazy(() => import("../pages/SupportEvents/view"));

/* Reservations */
const ReservationsCancel = lazy(() => import("../pages/Reservations/cancel"));

/* NoMatch */
const NoMatch = lazy(() => import("../pages/NoMatch/index"));

/* Login */
const Login = lazy(() => import("../pages/Login"));

// https://dev.to/finiam/predictable-react-authentication-with-the-context-api-g10
// https://github.com/finiam/phoenix_starter/
// https://react.i18next.com/latest/using-with-hooks

/**
 * @see https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx
 * @returns
 */
const PrivateOutlet = () => {
  const { isLogged } = useAuth();
  const location = useLocation();

  return isLogged() ? (
    <Suspense fallback={<ScreenLoader />}>
      <Layout>
        <Outlet />
      </Layout>
    </Suspense>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateOutlet />}>
        {/* EVENTOS */}
        <Route index element={<EventsIndex />} />
        <Route exact path="eventos/calendario" element={<EventsCalendar />} />
        <Route path="evento/:id" element={<EventsView />} />
        {/* RESERVAS */}
        <Route path="reservas/nueva" element={<ReservationsNew />} />
        {/* EVENTOS GRUPALES */}
        <Route
          exact
          path="eventos-grupales/nuevo"
          element={<GroupEventsNew />}
        />
        <Route path="evento-grupal/:id" element={<GroupEventsEdit />} />
        {/* EVENTOS APOYADOS */}
        <Route path="evento-apoyado/:id" element={<SupportEventsView />} />
        {/* PROFILE */}
        <Route exact path="perfil" element={<ProfileIndex />} />
        {/* 404 */}
        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route
        exact
        path="/login"
        element={
          <Suspense fallback={<ScreenLoader />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        exact
        path="/cancelar-reserva"
        element={
          <Suspense fallback={<ScreenLoader />}>
            <ReservationsCancel />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
