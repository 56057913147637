import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { isAfter, parseISO } from "date-fns";

import * as usersApi from "../api/users";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  /*
   * La autentición tiene dos parámetros:
   *
   * - `value`: String para las peticiones que debe viajar en la cabecera como
   *     `fs_token`.
   * - `expirationDate: Fecha de expiración del token en cuestión.
   */
  // const [token, setToken] = useLocalStorage("token", EMPTY_TOKEN);

  /*
   * Datos del usuario para que no sea necesario llamar a la API en todo
   * momento, ya que aparece en sitios comunes como la cabecera.
   */
  const [user, setUser] = useState();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  // Si la página cambia, resetea el estado del `error`.
  useEffect(() => {
    if (error) {
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // Check if there is a currently active session when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load is over.
  useEffect(() => {
    handleUser().finally(() => setLoadingInitial(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUser = async () => {
    return await usersApi
      .getCurrentUser()
      .then((response) => {
        if (!response.data?.success) {
          logout();

          return;
        }

        setUser(response.data.user);
      })
      .catch((_error) => {
        if (_error.status === 401) {
          logout();
        }
      });
  };

  /**
   * Trata de iniciar sesión en la aplicación.
   *
   * @param {Object} data Datos de acceso.
   * @param {String} data.username Nombre de usuario.
   * @param {String} data.password Contraseña de usuario.
   */
  const login = (username, password) => {
    setLoading(true);
    setError();

    usersApi
      .login(username, password)
      .then((response) => {
        if (!response.data?.success) {
          setError(new Error(response.errors.message));

          return;
        }

        setUser(response.data.user);
        navigate("/", { replace: true });
      })
      .catch((error) => {
        if (error.info?.errors?.message) {
          setError(new Error(error.info.errors.message));

          return;
        }

        setError(error);
      })
      .finally(() => setLoading(false));
  };

  /**
   * Cierra la sesión.
   */
  const logout = (goToLogin = false) => {
    usersApi.logout().then(() => {
      setUser(undefined);

      if (goToLogin) {
        navigate("/login", { replace: true });
      }
    });
  };

  /**
   * Determina si el usuario está logueado:
   *
   * - Tiene un token establecido.
   * - El token no ha expirado.
   *
   * @return {Boolean} Si el usuario está autenticado o no.
   */
  const isLogged = () => {
    return user ? true : false;
  };

  /**
   * Hace que el provider se actualice sólo en caso necesario.
   * Sólo tiene que forzar el re-renderizado cuando el usuario, el token, la
   * carga o el error cambian.
   *
   * Siempre que el valor pasado a un provider cambia, todo el árbol bajo dicho
   * provider se vuelve a renderizar, lo cual puede ser muy costoso. Para
   * mantener un buen rendimiento utilizamos este `useMemo`.
   */
  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      logout,
      isLogged,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loading, error]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
      {/* {children} */}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
